import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from "../../../common/component/BreadCrumb";
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import Table from '../../../common/component/Table';
import Confirmation from "../../../common/component/Confirmation"
import constantURL from "../../../common/api/constantURL";
import * as API from '../../../common/api/index.js';
import { setSnackData, setLoading } from '../../../redux/action/userAction';
import { setEvents } from '../../../redux/action/eventAction.js';
import { validatePermission } from "../../../common/functions/permissionFunctions";
import {downloadImage} from "../../../common/functions/commonFunctions.js";
import "./MusterLocation.css"
import moment from 'moment-timezone';
import AddEvents from './AddEvents.js';
function Events() {
	const dispatch = useDispatch();
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const eventsList = useSelector(state => state.eventReducer.events)
	const [showEditEvent, setShowEventPopUp] =  useState(false)
	const [confirmation,setConfirmation] = useState(false)
	const [confirmationDeleteData,setConfirmationDeleteData] = useState([])
	const [editModalData,setEditModalData] = useState({})
	const [search, setSearch] = useState('');
	
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/application/events",display:"Application",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon'/>,type:'img'},
		{link:"/home/application/events",display:"Events",type:"react"},
	];

	
    const eventQRurlCopy = async (row,page,data) => {
		try {
			const response = await fetch(row.qrImage);
			const blob = await response.blob();
			if (data === "qr") {
				try {
					const contentType = response.headers.get('Content-Type');
					const data = [new ClipboardItem({ [contentType]: blob })];
					await navigator.clipboard.write(data);
				} catch (error) {
					if (error.name === 'NotAllowedError' || error.message === "Document is not focused") {
						let snackData = {
							showSnack: true,
							snackMessage: "Clipboard operation failed because the document is not focused.",
							snackVariant: "error"
						}
						dispatch(setSnackData(snackData))
					} else {
						let snackData = {
							showSnack: true,
							snackMessage: "Browser does not have permission to copy text to clipboard.",
							snackVariant: "error"
						}
						dispatch(setSnackData(snackData))
					}
				}
			} 
			else if (data === "download") {
				downloadImage(blob,row.name)
			}
		} catch (error) {
			let snackData = {
				showSnack: true,
				snackMessage: "Please try again.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
       if (data === "url") {
		   try {
			   await navigator.clipboard.writeText(row.link)
		   } catch (error) {
			   if (error.name === 'NotAllowedError' || error.message === "Document is not focused") {
				   let snackData = {
					   showSnack: true,
					   snackMessage: "Clipboard operation failed because the document is not focused.",
					   snackVariant: "error"
				   }
				   dispatch(setSnackData(snackData))
			   } else {
				   let snackData = {
					   showSnack: true,
					   snackMessage: "Browser does not have permission to copy text to clipboard.",
					   snackVariant: "error"
				   }
				   dispatch(setSnackData(snackData))
			   }
		   }
        }
    }
    

	let tableHeaders = [
		{ id: 'name', label: 'Event Name', width: 73, responsive: true, sortable: true ,dataTestId:"event-name"},
		{ id: 'location', label: "Event Location", width: 100, sortable:true,dataTestId:"event-location" },
        { id: 'attendeesRegistered', label: "Attendees Registered", width: 65,dataTestId:"attendees-registered" },
        { id: 'link',type:"url", label: "Link",width: 170, dataTestId:"event-link" ,copy:true, clickFunc: eventQRurlCopy},
        { id: 'qrImage',type:"qr", label: "QR code", width: 60, responsive:false, height : 40, dataTestId:"event-qr",copy:true, clickFunc: eventQRurlCopy},
        { id: 'startDate', label: "Start Date & Time", responsive:true, width: 80, dataTestId:"start-date-time", sortable: true },
        { id: 'endDate', label: "End Date & Time", responsive:true, width: 80, dataTestId:"end-date-time", sortable: true },
	]
  

	useEffect(() => {
		dispatch(setLoading(true))
		fetchEvents();
	}, []); 

	const fetchEvents = async () => {
		let url = constantURL.eventsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = constantURL.eventsByFacility
		}
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let eventsList = response.result.result
				let newEventList =eventsList&& eventsList.map((event)=>{
					let tempObj = {}
					tempObj = event
					tempObj["propsStartDate"] =  moment(event.startDate).format("DD MMM, YYYY  hh:mm A")
					tempObj["propsEndDate"] =  moment(event.endDate).format("DD MMM, YYYY  hh:mm A")
					tempObj["startDate"] =  moment(event.startDate).format("MM/DD/YYYY hh:mm A")
					tempObj["endDate"] =  moment(event.endDate).format("MM/DD/YYYY hh:mm A")
					tempObj["format"] = "MM/DD/YYYY hh:mm A"
					return tempObj
				})
				dispatch(setEvents(newEventList))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
				dispatch(setEvents([]))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
			dispatch(setEvents([]))
		}
	}

	const showDeleteAction = (data) => {
		setConfirmation(true)
		setConfirmationDeleteData(data)
	}
	const showEditAction = (data) =>{

		setEditModalData(data)
		setShowEventPopUp(true)
	}

	const deleteEvents = async () => {
		let data = {
			"id": confirmationDeleteData.id,
			"facilityId": localStorage.getItem("facilityId"),
		}
		let response = await API.deleteAPI(constantURL.events, data)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "success"
				}
				setConfirmation(false)
				fetchEvents();
				dispatch(setSnackData(snackData))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	if (validatePermission(userPermissions, "EDIT_EVENTS") || validatePermission(userPermissions, "DELETE_EVENTS")) {
		tableHeaders.push({ id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 60, responsive: true })
	}
	
	const filter = (tempValues) => {
		let updatedValues = []
		if (search && search.length > 0) {
			tempValues.map((value) => {
				if (
					value.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
				) {
					updatedValues.push(value)
				}
			})
		} else {
			updatedValues = tempValues
		}
		return updatedValues
	}

	return (
		<div className='mustering-location-container' data-testid="events-location-container">
			<BreadCrumb  crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
			<div className='list-page-title-div'>
				<div className='list-page-title' data-testid="events-location-title">Events</div>
			</div>
			
			{validatePermission(userPermissions, "ADD_EVENTS") ?
				<div className='button_parent_div'>
					<button className='common-button' data-testid="add-event-btn" id="add-event" onClick={() => { setShowEventPopUp(true) }}>Add New Event</button>
				</div>
				
			:null}
			<div className="search mustering-location-search-filter">
				<input type="text" className="usersSearch" placeholder="Search" data-testid="event_location_search" id="event-location-search" 
				 value={search} onChange={(e) => setSearch(e.target.value)}
				></input>
				<div style={{ display: "flex" }} className="reclear">
					<div data-testid="search-reset-btn" id="search-reset-btn"  className='pointer' onClick={()=>{setSearch('')}} >Reset</div>
				</div>
			</div>
			<div>
				<Table
					headers={tableHeaders}
					data={filter(eventsList)}
					editAction={validatePermission(userPermissions, "EDIT_EVENTS") ? (data) => showEditAction(data) : null}
					deleteAction={validatePermission(userPermissions, "DELETE_EVENTS") ?(data) => showDeleteAction(data):null}
                    defaultOrderBy={"startDate"}
                    defaultSortOrder={"asc"}		
				/>
			</div>
			{confirmation ?
				<Confirmation deleteMessage={"Are you sure you want to delete event "+confirmationDeleteData.name+"?"} successFunc={() => deleteEvents()} cancelFunc={() => setConfirmation(false)} />
			: ""}
			{showEditEvent ? 
				<AddEvents 
					data={editModalData} 
					onCancel={(e) => {
					fetchEvents()
					setShowEventPopUp(false)
					setEditModalData({})
			}} 
						/>
			:null}
			
		</div>
	)
}

export default Events
