import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbIcon from '../../assets/images/breadcrum.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import Table from '../../common/component/Table'
import AddIntegration from "./AddIntegration";
import { setAccounts, setFacilities, } from '../../redux/action/accountAction';
import { setSnackData, setLoading } from '../../redux/action/userAction';
import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL";
import Confirmation from "../../common/component/Confirmation"
import '../Style/Integration.css';

function Integrations() {
	const dispatch = useDispatch();
	const facilities = useSelector(state => state.accountReducer.facilities)
	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "/home/system/account", display: "System", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "", display: "Integrations", type: "react" },
	];
	const [search, setSearch] = useState('');
	const [modalProps, setModalProps] = useState({});
	const [integrations, setIntegrations] = useState([]);
	const [showFacilityPopup, setShowFacilityPopup] = useState(false);
	const [confirmation, setConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] = useState(false);

	useEffect(() => {
		updateData();
	}, []);
	useEffect(()=>{

	}, [integrations, facilities])
	const updateData =  () => {
		fetchAccounts()
		fetchIntegrations()
	}
	const fetchAccounts = async () => {
		dispatch(setLoading(true))
		let response = await API.getAPI(URL.accounts)
		let accountList = response.result.result || []
		fetchFacility();
		dispatch(setAccounts(accountList))
	}
	const fetchFacility = async () => {
		let response = await API.getAPI(URL.facility)
		dispatch(setLoading(false))
		let facilityList = response.result.result || []
		dispatch(setFacilities(facilityList))
	}
	const fetchIntegrations = async () => {
		let response = await API.getAPI(URL.integrations)
		let integrationList = response.result.result || []
		setIntegrations(integrationList)
	}
	const formatData =()=>{
		let tempList = []
		integrations.map((data)=>{
			let tempData = data
			facilities.map((facility)=>{
				if( facility.id == data.facilityId){
					tempData["facilityName"] = facility.siteName
				}
			})
			tempList.push(tempData)
		})
		return tempList
	}
	
	const editDetails = (data) => {
		setModalProps(data)
		setShowFacilityPopup(true)
	}
	const showDeleteAction = (data) => {
		setConfirmation(true)
		setConfirmationData(data)
	}
	const deleteFacility = async () => {
		let data = {
			"id": confirmationData.id
		}
		let response = await API.deleteAPI(URL.integrations, data)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "success"
				}
				setConfirmation(false)
				updateData();
				dispatch(setSnackData(snackData))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Unable to delete. Try Again!",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const filter = (values) => {
		let updatedValues = []
		if (search && search.length > 0) {
			values.map((value) => {
				if (
					value.firstName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
				) {
					updatedValues.push(value)
				}
			})
		} else {
			updatedValues = values
		}
		return updatedValues
	}
	let formattedData = formatData()
	return (
		<div className="integrations-page-container">
			<BreadCrumb crumbs={BreadCrumbArr}></BreadCrumb>
			<div className='list-page-title-div'>
				<div className="list-page-title" data-testid="integration-management-title" id="integration-management-title">Integration Management</div>
			</div>
			<div className='integrations-action-container'>
				<button className="common-button" data-testid="add-integration-btn" onClick={() => setShowFacilityPopup(true)}>Add Integration</button>
				<div className='account-filter-ctr'>
					<input type="text" className='integrations-search-input' data-testid="integration-search" placeholder='Search' value={search}  onChange={(e)=>{setSearch(e.target.value)}}></input>
					<div style={{ display: "flex" }} className="reclear">
						<div data-testid="users-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setSearch('')}} >Reset</div>
					</div>
				</div>
			</div>
			<Table
				headers={[
					{ id: 'firstName', label: 'Integration Name',dataTestid:"integration-name", width: 100, responsive: true, sortable: true },
					{ id: 'userName', label: 'Integration User Id',dataTestid:"integration-user-id",width: 80, sortable: true },
					{ id: 'facilityName', label: 'Facility',dataTestid:"integration-user-id",width: 80},
					{ id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 70, responsive: true }
				]}
				tableTitle={"Integrations"}
				data={filter(formattedData)}
				editAction={(data) => { editDetails(data) }}
				deleteAction={(data) => showDeleteAction(data)}
				defaultOrderBy={"firstName"}
				defaultSortOrder={"asc"}
			/>
			{showFacilityPopup ?
				<AddIntegration
					data={modalProps}
					onCancel={(e) => {
						setShowFacilityPopup(false)
						setModalProps({})
						updateData()
					}}
				/>
			: null}
			{confirmation ?
				<Confirmation deleteMessage={"Are you sure you want to delete integration "+confirmationData.firstName+"?"}  successFunc={() => deleteFacility()} cancelFunc={() => setConfirmation(false)} />
			: null}
		</div>
	)
}
export default Integrations;
