import React, { useState, useEffect } from 'react'
import BreadCrumbIcon from '../../assets/images/breadcrum.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import ButtonField from '../../common/component/ButtonField';
import Table from "../../common/component/Table"
import "../Style/CreateViewAlert.css"
import ManageAlarm from './ManageAlarm';
import { setUser, setSnackData, setAlertIcon, setAlertColor, setLoading } from '../../redux/action/userAction';
import { setGroups } from '../../redux/action/groupAction';
import { validatePermission } from "../../common/functions/permissionFunctions";
import * as API from '../../common/api/index.js';
import URL from "../../common/api/constantURL";
import { useDispatch, useSelector } from 'react-redux';
import { setAlarms } from '../../redux/action/alarmAction';
import Confirmation from "../../common/component/Confirmation";
import { setDevices} from '../../redux/action/deviceAction';
function Alarm() {
	const dispatch = useDispatch()
	const alarms = useSelector(state => state.alarmReducer.alarmsByFacilityId)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "/home/application/mustering", display: "Application", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "", display: "Create & View Alarms", type: "react" },
	];
	const [alertName, setAlertName] = useState('')
	const [search, setSearch] = useState('')
	const [showCreatePopUp, setShowCreatePopUp] = useState(false)
	const [confirmation, setConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] = useState(false);
	const [modalProps, setModalProps] = useState({});
	let tableHeaders = [
		{ id: 'name', label: 'Alarm Name', width: 100, responsive: true, sortable: true,dataTestid:'name'},
		{ id: '', label: "Button", type: 'alarmButton', width: 150, responsive: true },
	]
	let alertFilter = [];
	alarms && alarms.forEach(function (item) {
		var i = alertFilter.findIndex(x => x.name == item.name);
		if (i <= -1) {
			alertFilter.push({ id: item.id, name: item.name });
		}
	});
	const fetchAlarms = async () => {
		let url = URL.alarmsByFacilityId + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.alarmsByFacilityId
		}
		let response = await API.getAPI(url)
		fetchIcon();
		let alarmList = response.result.result;
		dispatch(setAlarms(alarmList))
	}
	const showEditAlert = (data) => {
		fetchAlarms();
		setModalProps(data)
		setShowCreatePopUp(true)
	}

	const showDeleteAction = (data) => {
		setConfirmation(true)
		setConfirmationData(data)
	}

	useEffect(() => {
		dispatch(setLoading(true))
		fetchAlarms();
	}, [])

	const fetchIcon = async () => {
		let url = URL.iconByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.icon
		}
		let response = await API.getAPI(url)
		fetchColor();
		if (response.fetchStatus === "success") {
			dispatch(setAlertIcon(response.result))
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const fetchColor = async () => {
		let response = await API.getAPI(URL.color + "?facilityId=2")
		fetchUser();
		if (response.fetchStatus === "success") {
			dispatch(setAlertColor(response.result))
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const fetchGroup = async () => {
		let url = URL.groupsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.groups
		}
		let response = await API.getAPI(url)
		fetchDevice()
		let groupList = response.result.result
		for (let i = 0; i < groupList.length; i++) {
			groupList[i].userCount = groupList[i].userIds.length
			if (groupList[i].isEventGroup) {
				groupList.splice(i,1)
				i--
			}
		}
		dispatch(setGroups(groupList))
	}

	const fetchDevice = async () => {
		let url = URL.devices + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.devices
		}
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			let devicesList = response.result.result
			if(response.result.status === "success"){
				dispatch(setDevices(devicesList));
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const deleteAlarm = async () => {
		dispatch(setLoading(true))
		let data = {
			"id": confirmationData.id,
			"facilityId": localStorage.getItem("facilityId"),
		}
		let response = await API.deleteAPI(URL.alarms, data)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "success"
				}
				setConfirmation(false)
				fetchAlarms();
				dispatch(setSnackData(snackData))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const fetchUser = async () => {
		let url = URL.usersByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.users
		}
		let response = await API.getAPI(url)
		fetchGroup();
		let userList = response.result.result
		dispatch(setUser(userList));
	}

	const filter = (values) => {
		let tempValues = [];
		if (alertName && alertName.length > 0) {
			values.map((value) => {
				if (value.name === alertName) {
					tempValues.push(value)
				}
			})
		} else {
			tempValues = values
		}
		let updatedValues = []
		if (search && search.length > 0) {
			tempValues.map((value) => {
				if (value.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
					updatedValues.push(value)
				}
			})
		} else {
			updatedValues = tempValues
		}
		if (!alertName && !search) {
			tempValues = values;
		}
		return updatedValues
	}
	if (validatePermission(userPermissions, "EDIT_ALARMS") || validatePermission(userPermissions, "DELETE_ALARMS")) {
		tableHeaders.push({ id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 70, responsive: true })
	}
	
	return (
		<div className='create-view-alert-container' data-testid="create-view-alert-container">
			<BreadCrumb crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
			<div>
				<div  className='list-page-title-div'>
					<div className='list-page-title' data-testid="create-view-alert-title">Create and View Alarms</div>
				</div>
				{validatePermission(userPermissions, "ADD_ALARMS") ?
					<div className='create-new-group-btn'>
						<ButtonField label="Create New Alarm" onClick={(e) => { setShowCreatePopUp(true) }} dataTestid="add-alarm-btn" id="add-alarm-btn"/>
					</div>
				: null}
				
				<div className='alert-filter-container'>
					<div className='alert-select-filter'>
						<label className='alert-bold-label' data-testid="select-box-label">Alarm Name</label>
						<select value={alertName} onChange={(e) => { setAlertName(e.target.value) }} data-testid="alarm-select" id="alarm-select">
							<option value="">All</option>
							{alertFilter && alertFilter.map((alarms, index) =>
								<option key={index} id={"alarm-list-"+index}>{alarms.name}</option>
							)}
						</select>
					</div>
					<div className="search alert-search-filter">
						<input type="text" className="usersSearch" placeholder="Search" value={search} onChange={(e) => { setSearch(e.target.value) }} data-testid="alarm-serchbox"></input>
					</div>
					<div style={{ display: "flex" }} className="reclear alarm-reset-btn-ctr">
						<div data-testid="users-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setSearch('');setAlertName("")}} >Reset</div>
					</div>
				</div>
				<div>
					<Table
						headers={tableHeaders}
						data={filter(alarms)}
						editAction={validatePermission(userPermissions, "EDIT_ALARMS") ? (data) => { showEditAlert(data) }:null}
						deleteAction={validatePermission(userPermissions, "DELETE_ALARMS") ? (data) => showDeleteAction(data):null}
					/>
				</div>
			</div>
			{confirmation ?
				<Confirmation deleteMessage = {"Are you sure you want to delete alarm "+confirmationData.name+"?"} successFunc={() => deleteAlarm()} cancelFunc={() => setConfirmation(false)} />
			: ""}
			{showCreatePopUp &&
				<ManageAlarm
					data={modalProps}
					onCancel={(e) => {
						setShowCreatePopUp(false)
						setModalProps({})
						fetchAlarms()
					}}
				/>
			}
		</div>
	)
}
export default Alarm
